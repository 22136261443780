import React, { FC, SVGProps } from 'react';

export const USDCIcon: FC<SVGProps<SVGSVGElement>> = ({ width = 32, height = 32 }) => {
    return (
        <svg width={width} height={height} fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16-8.83656 0-16 7.16344-16 16 0 8.8366 7.16344 16 16 16z"
                fill="#2775ca"
            />
            <g fill="#ffffff">
                <path d="m13.0667 27.0663c0 .4-.2667.5333-.6667.5333-4.93333-1.6-8.4-6.1333-8.4-11.4667 0-5.3333 3.46667-9.86662 8.4-11.46662.4-.13334.6667.13333.6667.53333v.93333c0 .26667-.1334.53334-.4.66667-3.8667 1.46667-6.53337 5.06669-6.53337 9.33329 0 4.2667 2.8 8 6.53337 9.3334.2666.1333.4.4.4.6666z" />
                <path d="m17.0665 23.7335c0 .2667-.2667.5334-.5334.5334h-1.0666c-.2667 0-.5334-.2667-.5334-.5334v-1.6c-2.1333-.2666-3.2-1.4666-3.6-3.2 0-.2666.1334-.5333.4-.5333h1.2c.2667 0 .4.1333.5334.4.2666.9333.8 1.7333 2.5333 1.7333 1.3333 0 2.2667-.6666 2.2667-1.7333s-.5334-1.4667-2.4-1.7333c-2.8-.4-4.1334-1.2-4.1334-3.4667 0-1.7333 1.3334-3.0667 3.2-3.3333v-1.60002c0-.26667.2667-.53334.5334-.53334h1.0666c.2667 0 .5334.26667.5334.53334v1.60002c1.6.2666 2.6666 1.2 2.9333 2.6666 0 .2667-.1333.5334-.4.5334h-1.0667c-.2666 0-.4-.1334-.5333-.4-.2667-.9334-.9333-1.3334-2.1333-1.3334-1.3334 0-2 .6667-2 1.6 0 .9334.4 1.4667 2.4 1.7334 2.8.4 4.1333 1.2 4.1333 3.4666 0 1.7334-1.3333 3.2-3.3333 3.6z" />
                <path d="m19.5999 27.6006c-.4.1333-.6667-.1334-.6667-.5334v-.9333c0-.2667.1334-.5333.4-.6667 3.8667-1.4666 6.5334-5.0666 6.5334-9.3333s-2.8-8-6.5334-9.33333c-.2666-.13333-.4-.4-.4-.66667v-.93333c0-.4.2667-.53333.6667-.53333 4.8 1.6 8.4 6.13336 8.4 11.46666s-3.4667 9.8667-8.4 11.4667z" />
            </g>
        </svg>
    );
};
